import { render, staticRenderFns } from "./policy-tables-configure-details.vue?vue&type=template&id=78d23e40&scoped=true&"
import script from "./policy-tables-configure-details.vue?vue&type=script&lang=js&"
export * from "./policy-tables-configure-details.vue?vue&type=script&lang=js&"
import style0 from "./policy-tables-configure-details.vue?vue&type=style&index=0&id=78d23e40&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78d23e40",
  null
  
)

export default component.exports