<template>
	<validation-observer #default="{ handleSubmit }">
		<Modal
			width="850px"
		>
			<template #modal-title>
				Edit Cookie Script Description
				<v-spacer />
				<IconButton
					@click="$emit('cancelForm')"
				>
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<TextField
						v-model="formData.cookieName"
						label="Name*"
						class="cassie-input-width-xl"
						disabled
					/>
					<div class="d-flex flex-column">
						<FroalaEditor
							v-if="!isLoadingModal && userCanCreateUpdate"
							v-model="formData.cookieDescription"
							class="cassie-input-width-xl"
							title="Cookie Script Description"
							:rules="{required: true, max: 2000}"
						/>
						<v-textarea
							v-else
							v-dompurify-html="formData.cookieDescription"
							light
							dense
							outlined
							readonly
							class="mt-2 cassie-disabled-textarea cassie-input-width-xl"
						/>
					</div>
				</div>
			</template>
			<template #modal-footer>
				<v-spacer />
				<SecondaryActionButton
					@click="$emit('cancelForm')"
				>
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					:disabled="!userCanCreateUpdate"
					@click="handleSubmit(updateScriptDescription(formData))"
				>
					Save
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import FroalaEditor from '../../../../shared/froala-editor.vue'
import Modal from '../../../../../../../shared/components/modal.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import { CAN_CREATE_UPDATE_POLICY_TABLES } from '../../../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: {
		FroalaEditor,
		Modal,
		TextField,
		IconButton,
		PrimaryActionButton,
		SecondaryActionButton
	},
	props: {
		form: {
			type: Object
		}
	},
	data () {
		return {
			formData: {
				loading: true,
				cookieName: '',
				cookieScriptId: Number,
				cookieDescription: '',
				cookieScriptChannelId: Number
			},
			isValid: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_POLICY_TABLES)
		},
		isLoadingModal () {
			return this.formData.loading
		}
	},
	mounted () {
		this.formData.loading = false
		this.formData.cookieName = this.form.cookieName
		this.formData.cookieScriptId = this.form.cookieScriptId
		this.formData.cookieDescription = this.form.cookieDescription
		this.formData.cookieScriptChannelId = this.form.cookieScriptChannelId
	},
	methods: {
		updateScriptDescription (form) {
			this.$emit('updateScriptDescription', form)
		}
	}
}
</script>

<style lang="scss" scoped>
.cassie-disabled-button {
  color: var(--global-cassie-disabled-button-color);
  background-color: var(--global-cassie-disabled-button-background-color);
  border: var(--global-cassie-disabled-button-border);
  -webkit-box-shadow: revert;
  box-shadow: revert;
}

.cassie-disabled-textarea {
  padding: 10px !important;
  color: var(--global-cassie-disabled-textarea-color);
  border: var(--global-cassie-disabled-textarea-border);
}

.cassie-html-field-container > div {
  padding-top: 16px !important;
}

.cassie-overlay-card {
  max-height: 70vh !important;
  overflow-y: auto !important;
}

@media (max-width: var(--global-cassie-breakpoint-md)) {
  .cassie-overlay-card {
    height: 75vh;
  }
}
@media (max-width: var(--global-cassie-breakpoint-lg)) {
  .cassie-overlay-card {
    height: 85vh;
  }
}
</style>
