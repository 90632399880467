import { render, staticRenderFns } from "./policy-tables-configure-script-details-form.vue?vue&type=template&id=f0606e82&scoped=true&"
import script from "./policy-tables-configure-script-details-form.vue?vue&type=script&lang=js&"
export * from "./policy-tables-configure-script-details-form.vue?vue&type=script&lang=js&"
import style0 from "./policy-tables-configure-script-details-form.vue?vue&type=style&index=0&id=f0606e82&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0606e82",
  null
  
)

export default component.exports